<template>
  <div class="left">
    <div class="leftTop1">
      <!-- <dv-borderBox12 class="left-top-borderBox12"> -->
      <div class="left-top1">
        <!-- @click="handlerClick" -->
        <div class="module-title">
          <icon name="chart-bar" class="iconfont"></icon>
          <span class="title_text_shadow">地区连锁加盟分布</span>
        </div>
        <!-- areaList -->
        <div v-if="!isShowTable" style="width:38rem;height:11.4rem">
          <dv-scroll-board
            :config="areaList"
            style="width:100%;height:100%;margin-left:5px"
          />
        </div>
        <BarAndLine
          v-if="isShowTable"
          width="100%"
          height="11.4rem"
        ></BarAndLine>
        <!-- </div> -->
        <div class="left-top2">
          <div class="top2_one">
            <div class="module-title">
              <icon name="chart-pie" class="iconfont"></icon>
              <span class="title_text_shadow">同益加绑定率</span>
            </div>
            <div class="bind_percent">
              <dv-decoration-9 style="width:10rem;height:10rem;font-size:28px;color: white;" >83.6%</dv-decoration-9>
            </div>
          </div>
          <div class="top2_one">
            <div class="module-title">
              <icon name="chart-pie" class="iconfont"></icon>
              <span class="title_text_shadow">打卡考勤率</span>
            </div>
            <div class="bind_percent">
              <dv-decoration-9 style="width:10rem;height:10rem;font-size:28px;color: white;" >64%</dv-decoration-9>
            </div>
          </div>
          <div class="top2_one">
            <div class="module-title">
              <icon name="chart-pie" class="iconfont"></icon>
              <span class="title_text_shadow">工作场景照片上传率</span>
            </div>
            <div class="bind_percent">
              <dv-decoration-9 style="width:10rem;height:10rem;font-size:28px;color: white;" >51%</dv-decoration-9>
            </div>
          </div>
          <!-- <div>
            <div class="module-title">
              <icon name="chart-line" class="iconfont"></icon>
              <span class="title_text_shadow">日活跃数据走势</span>
            </div>
            <LineProfile
              width="19rem"
              height="13rem"
              chatId="dayline"
            ></LineProfile>
          </div> -->
        </div>
      </div>
      <!-- </dv-borderBox12> -->
    </div>
    <div class="leftTop2">
      <!-- <dv-borderBox12 class="left-top-borderBox12"> -->
      <div class="left-top2">
        <div class="top2_one">
          <div class="module-title">
            <icon name="chart-pie" class="iconfont"></icon>
            <!-- 本月入户问需  -->
            <span class="title_text_shadow">本月需求</span>
          </div>
          <div class="bind_percent">
            <BarRow width="19rem" height="17rem"></BarRow>
          </div>
        </div>
        <div>
          <div class="module-title">
            <!-- <icon name="chart-line" class="iconfont"></icon>
            <span class="title_text_shadow">本月其他需求</span> -->
            <span
              class="title_text_shadow"
              style="width: 95%;text-align: end;text-shadow: none;"
              >已完成/月度目标：187/202</span
            >
          </div>

          <BarEmp
            id="barclom"
            width="19rem"
            height="17rem"
            :xAxis="columx"
            :yAxis1="columy1"
            :yAxis2="columy2"
            :yName1="columN1"
            :xgrid="columG1"
            title=" "
          ></BarEmp>
        </div>
      </div>
    </div>

    <div class="leftTop3">
      <!-- <dv-borderBox12 class="left-top-borderBox12"> -->
      <div class="left-top2">
        <div>
          <div class="module-title">
            <icon name="chart-area" class="iconfont"></icon>
            <span class="title_text_shadow">劳动产品订单</span>

            <span style="color:#fff;font-size:14px;margin-left:12rem">
              累计件数：3,481
              <span style="margin-left:20px"> 累计金额：￥1,683,417</span>
            </span>
          </div>
          <div style="width:38rem;height:12rem">
            <dv-scroll-board
              :config="peixunList"
              style="width:100%;height:100%;margin-left:5px"
            />
          </div>
        </div>
      </div>
      <!-- </dv-borderBox12> -->
    </div>
  </div>
</template>

<script>
import flvjs from "flv.js"
// import PieFeed from "@/components/wchart/pie_feed.vue"
// import LineProfile from "@/components/wchart/line_profile.vue"
import BarRow from "@/components/wchart/bar_row.vue"
// import BarColumTwo from "@/components/wchart/bar_colum_two.vue"
import BarEmp from "@/components/wchart/bar_emp.vue"
import BarAndLine from "@/components/wchart/bar_and_line.vue"

export default {
  components: {
    // PieFeed,
    // LineProfile,
    BarRow,
    // BarColumTwo,
    BarEmp,
    BarAndLine,
  },
  data() {
    return {
      isShowTable: false,
      columx: ["融合活动", "志愿服务", "工作者培训"],
      columy2: [64, 32, 16],
      columy1: [62, 31, 16],
      columN1: ["完成数", "申请数"],
      columG1: {
        top: "10%",
        right: "10%",
        left: "0%",
        bottom: "20%",
      },
      config: {
        // 表头背景色
        headerBGC: "#443dc5",
        // 奇数行背景色
        oddRowBGC: "#09184F",
        // 偶数行背景色
        evenRowBGC: "#070C34",
        // 行号
        index: true,
        // 行号表头
        indexHeader: "序号",
        // 宽度
        columnWidth: [100, 200, 100],
        // 对其方式
        align: ["center"],
        // 表行数
        rowNum: 10,
        header: ["公司", "助残人数"],
        data: [
          ["美团", "1682人"],
          ["北京地铁", "1206人"],
          ["北京公交集团", "707人"],
          ["小米集团", "693人"],
          ["北汽集团", "683人"],
          ["ABB中国", "560人"],
          ["新华三", "357人"],
          ["施耐德", "350人"],
          ["FESCO", "334人"],
          ["字节跳动", "314人"],
          ["上汽集团", "310人"],
          ["北京城建", "213人"],
        ],
      },
      accessFrequency: 1500,
      peakFlow: 300,
      trafficSitua: {
        timeList: ["2018", "2019", "2020", "2021", '2022','2023', '2024'],
        outData: [16286, 29314, 41542, 47570, 50998, 59426, 78868],
        inData: [1628, 2931, 4454, 4857, 5299, 6042, 8886],
      },
      feedData: { title: "小程序绑定率", number: 87 },

      peixunlvData: [
        {
          name: "累计就业人数",
          value: "38,666",
          unit: "人",
        },
        {
          name: "就业基地数",
          value: "362",
          unit: "个",
        },
        {
          name: "惠及城市数",
          value: "60",
          unit: "个",
        },
        {
          name: "服务企业数",
          value: "3,523",
          unit: "家",
        },
        {
          name: "帮扶残疾大学生",
          value: "5,842",
          unit: "人",
        },
        {
          name: "企业助残续签率",
          value: "92",
          unit: "%",
        },
      ],
      flv1:
        "https://v2sig796aq5z2fcev2joqb1faqwshelq5.mobgslb.tbcache.com/dtlive-bj.dingtalk.com/live_hp/f92f930d-f592-49b9-a620-0db74c5275e4.flv?auth_key=1689409299-0-0-eda4d52465236b63c0898272ed597cfa&ali_redirect_ex_hot=100",
      flv2:
        "https://v2im2xgcloq1lnvuvrqpi27xd4emma3ml.mobgslb.tbcache.com/dtlive-sz.dingtalk.com/live_hp/09ee128e-5ede-4459-832b-883f6c032381.flv?auth_key=1689330950-0-0-b9516f5f564f51d09357711224894229&ali_redirect_ex_hot=100",
      peixunList: {
        header: [
          "采购单位",
          "产品订单",
          "数量",
          "涉及基地",
          "金额",
          "状态",
          "下单时间",
        ],
        data: [
          [
            "西门子",
            "兔爷萌娃",
            "20",
            "未分配",
            "￥790",
            "已下单",
            "2024/5/21",
          ],
          [
            "西门子",
            "兔爷福将",
            "20",
            "未分配",
            "￥790",
            "已下单",
            "2024/5/21",
          ],
          [
            "FESCO",
            "瓷胎竹编",
            "10",
            "未分配",
            "￥6000",
            "已下单",
            "2024/5/21",
          ],
          [
            "小米",
            "花草杯垫",
            "200",
            "未分配",
            "￥7600",
            "已下单",
            "2024/5/21",
          ],
          [
            "BOE",
            "融爱文创礼盒",
            "50",
            "未分配",
            "￥16000",
            "已下单",
            "2024/5/20",
          ],
          [
            "施耐德",
            "铃兰小夜灯",
            "20",
            "未分配",
            "￥2560",
            "已审核",
            "2024/5/18",
          ],
          [
            "电子城",
            "铃兰小夜灯",
            "200",
            "市级站",
            "￥25600",
            "已审核",
            "2024/5/17",
          ],
          ["奥迪", "剪纸", "8", "马驹桥镇", "￥784", "已审核", "2024/5/16"],
          ["诺基亚", "剪纸", "2", "张山营镇", "￥196", "已审核", "2024/5/15"],
          [
            "诺基亚",
            "景泰蓝书签",
            "2",
            "张山营镇",
            "￥470",
            "已审核",
            "2024/5/15",
          ],
          [
            "华友钴业",
            "茶叶",
            "4",
            "千岛湖镇",
            "￥2400",
            "已审核",
            "2024/5/15",
          ],
          [
            "华友钴业",
            "茶叶",
            "4",
            "千岛湖镇",
            "￥2400",
            "已审核",
            "2024/5/15",
          ],
          [
            "诺基亚",
            "定制帆布袋",
            "400",
            "市级站",
            "￥14000",
            "已审核",
            "2024/5/15",
          ],
          [
            "燕东微电子",
            "车挂",
            "200",
            "太阳宫地区",
            "￥7200",
            "已审核",
            "2024/5/14",
          ],
          [
            "万达",
            "花草托盘",
            "20",
            "望京街道",
            "￥1960",
            "已审核",
            "2024/5/14",
          ],
          ["美团", "波波球", "10", "儒林街道", "￥780", "已发货", "2024/5/9"],
          [
            "上海慧与",
            "永生花微景观",
            "30",
            "刘斌堡乡",
            "￥1950",
            "已发货",
            "2024/5/9",
          ],
          [
            "北京华联",
            "兔爷萌娃",
            "10",
            "市级站",
            "￥380",
            "已发货",
            "2024/5/6",
          ],
          [
            "新华三",
            "定制帆布袋",
            "200",
            "市级站",
            "￥7000",
            "已签收",
            "2024/5/1",
          ],
          ["ABB", "星座团子", "36", "马驹桥镇", "￥2520", "已签收", "2024/5/1"],
          [
            "FESCO",
            "融爱非遗礼盒",
            "10",
            "马驹桥镇",
            "￥3200",
            "已签收",
            "2024/4/30",
          ],
          [
            "西门子",
            "繁花似锦化妆镜",
            "10",
            "永顺镇",
            "￥1690",
            "已签收",
            "2024/4/15",
          ],
          [
            "西门子",
            "繁花似锦化妆镜",
            "10",
            "永顺镇",
            "￥1690",
            "已签收",
            "2024/4/15",
          ],
        ],
        index: true,
        columnWidth: [50, 80, 110, 50, 80, 80, 65],
        align: ["center"],
        rowNum: 4,
        headerHeight: 30,
        headerBGC: "#5689EA",
        oddRowBGC: "",
        evenRowBGC: "",
      },

      areaList: {
        header: ["地区", "连锁类型", "基地数量", "服务残疾人数"],
        data: [
          ["北京", "直营", "6", "3086"],
          ["北京", "加盟", "179", "4737"],
          ["上海", "加盟", "34", "683"],
          ["浙江", "加盟", "67", "469"],
          ["四川", "加盟", "6", "418"],
          ["天津", "加盟", "6", "242"],
          ["湖北", "加盟", "4", "236"],
          ["重庆", "加盟", "2", "178"],
          ["山东", "加盟", "2", "122"],
          ["江苏", "加盟", "5", "83"],
          ["辽宁", "加盟", "2", "79"],
          ["陕西", "加盟", "1", "69"],
          ["福建", "加盟", "1", "62"],
          ["河南", "加盟", "1", "46"],
          ["广东", "加盟", "1", "44"],
          ["河北", "加盟", "4", "34"],
          ["湖南", "加盟", "1", "26"],
          ["云南", "加盟", "1", "25"],
          ["内蒙古", "加盟", "1", "19"],
          ["安徽", "加盟", "1", "19"],
          ["黑龙江", "加盟", "1", "19"],
          ["西藏", "加盟", "1", "17"],
          ["新疆", "加盟", "1", "9"],
          ["吉林", "加盟", "1", "8"],
          ["江西", "加盟", "1", "8"],
          ["广西", "加盟", "1", "5"],
          ["贵州", "加盟", "1", "5"],
          ["海南", "加盟", "1", "5"],
          ["山西", "加盟", "1", "4"],
        ],
        index: true,
        columnWidth: [100, 150, 150, 150, 150],
        align: ["center", "center", "center", "center", "center"],
        rowNum: 4,
        headerHeight: 30,
        headerBGC: "#5689EA",
        oddRowBGC: "",
        evenRowBGC: "",
      },
    }
  },
  mounted() {
    // this.flashPlay(this.flv1, this.$refs.video1)
    // this.flashPlay(this.flv2, this.$refs.video2)
  },
  methods: {
    handlerClick() {
      if (this.isShowTable) {
        this.isShowTable = false
      } else {
        this.isShowTable = true
      }
    },
    flashPlay(url, elementId) {
      if (flvjs.isSupported()) {
        const flvPlayer = flvjs.createPlayer(
          {
            type: "flv",
            isLive: true,
            url: url, // 替换为您的FLV文件路径或URL
          },
          {
            //Config
            enableWorker: false,
            enableStashBuffer: false,
            //当带音频播放时,config部分配置项尽量采取默认状态,否则过分优化会造成卡死
          }
        )

        flvPlayer.attachMediaElement(elementId)
        flvPlayer.load()
        flvPlayer.play()
        // elementId.play()
      } else {
        console.error("FLV.js is not supported in this browser")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left {
  width: 42rem;
  height: auto;
  padding-top: 1.2rem;
  padding-bottom: 0px;
}
.leftTop1 {
  position: relative;
  height: 29.8rem;
  width: 100%;
  .left-top1 {
    width: 100%;
    border-radius: 10px;
  }
}
.leftTop2 {
  position: relative;
  height: 18rem;
  width: 100%;
}
.leftTop3 {
  position: relative;
  margin-top: 0.5rem;
  height: 14rem;
  width: 100%;
}
.left-top2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;

  .top2_one {
    width: 100%;
    .bind_percent {
      width: 100%;
      height: 13rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .dis-text {
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
.left-top-borderBox12 {
  width: inherit;
  height: inherit;
  padding: 5px;
}
.left-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: rgba(13, 28, 83, 0.3);
}

.module-title {
  padding: 10px 10px;
  color: #bcdcff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}

.detail-item-text {
  span {
    font-weight: 500px;
    font-size: 1rem;
    font-weight: bolder;
    background: linear-gradient(to bottom, #fff, #4db6e5);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}
.centerBottom {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  align-items: center;
  .detail-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    width: 100%;
    &-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      height: 6.5rem;
      width: 49%;
      margin: 0.3rem 0;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid #244d85;
      background-color: rgba(13, 28, 83, 0.3);
      margin-left: 0.225rem;
      font-size: 1.3rem;
      h3 {
        color: #bcdcff;
        margin-bottom: 0.3rem;
        font-size: 0.8rem;
      }
      span {
        font-weight: 500px;
        font-weight: bolder;
        background: linear-gradient(to bottom, #fff, #4db6e5);
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
      .unit {
        margin-left: 0.225rem;
        font-size: 0.8rem;
      }
    }
  }
}

.left-video {
  width: 100%;
  .video_item {
    width: 100%;
    height: 15rem;
    padding: 0px 0.3rem;
  }
}
.responsive-iframe {
  width: 19rem;
  border-radius: 10px;
  height: 14.5rem;
}
</style>
