<template>
  <div class="centerPage">
    <div class="mapWrapper">
      <mapjidiVue width="39rem" height="34.4rem"></mapjidiVue>
      <div class="storeWrapper">
          <p>基地总数：355</p>
          <p>公益劳动型帮扶性就业基地：216 家</p>
          <p>职康劳动型帮扶性就业基地：137 家</p>
          <p>帮扶性就业车间：2 家</p>
      </div>
    </div>
    <div class="center2">
      <!-- <dv-borderBox12 class="right-top-borderBox12"> -->
      <div class="right-top">
        <div class="left-video">
          <div class="module-title">
            <!-- <icon name="chart-area" class="iconfont"></icon> -->
            <img style="width:30px;height:30px" src="@/assets/video.png" />
            <span class="title_text_shadow">{{zkzVideo.live_name || ''}}</span>
          </div>
          <div class="video_item">
            <!-- v-if="" -->
            <video
              ref="video1"
              :controls="false"
              controlslist="nodownload noplaybackrate"
              disablepictureinpicture="true"
              disableRemotePlayback="true"
              v-show="zkzVideo.live_type === 1"
              class="responsive-iframe"
              autoplay
              loop
              muted
              id="video1"
              @click="onClickVideo('video1')"
            ></video>
            <video
              v-if="zkzVideo.live_type === 2"
              :controls="false"
              controlslist="nodownload noplaybackrate"
              disablepictureinpicture="true"
              disableRemotePlayback="true"
              class="responsive-iframe"
              autoplay
              :src="zkzVideo.live_url"
              loop
              muted
              id="video2"
              @click="onClickVideo('video2')"
            ></video>
          </div>
        </div>
        <div class="left-video">
          <div class="module-title">
            <!-- <icon name="chart-area" class="iconfont"></icon> -->
            <img style="width:30px;height:30px" src="@/assets/video.png" />

            <span class="title_text_shadow">{{zsyVideo.live_name || ''}}</span>
          </div>
          <div class="video_item">
            <video
              ref="video2"
              :controls="false"
              controlslist="nodownload noplaybackrate"
              disablepictureinpicture="true"
              disableRemotePlayback="true"
              v-show="zsyVideo.live_type === 1"
              class="responsive-iframe"
              autoplay
              muted
              id="video3"
              @click="onClickVideo('video3')"
            ></video>
            <video
              v-if="zsyVideo.live_type === 2"
              :controls="false"
              controlslist="nodownload noplaybackrate"
              disablepictureinpicture="true"
              disableRemotePlayback="true"
              class="responsive-iframe"
              autoplay
              :src="zsyVideo.live_url"
              loop
              muted
              id="video4"
              @click="onClickVideo('video4')"
            ></video>
          </div>
        </div>
      </div>
      <!-- </dv-borderBox12> -->
    </div>
    <div class="center3">
      <!-- <dv-borderBox12 class="right-top-borderBox12">
        <div class="right-top"> -->
      <div>
        <div class="module-title">
          <icon name="chart-area" class="iconfont"></icon>
          <span class="title_text_shadow">残疾人就业场景</span>
        </div>
        <!-- width:38rem;height:9.6rem; -->
        <div style="width:39.5rem;padding:0 10px">
          <swiper :options="swiperOption">
            <swiper-slide
              v-for="item in 21"
              :key="item"
              class="ele_image_lunbo"
            >
              <img :src="require(`@/assets/yanlei/${item + 1}.jpg`)" alt="" />
            </swiper-slide>

            <!-- <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/yanlei/1.jpg" alt="" />
            </swiper-slide> -->

            <!-- @swiper="onSwiper"
                @slideChange="onSlideChange" -->
            <!-- <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/activity/1.jpg" alt="" />
            </swiper-slide>
            <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/activity/2.jpg" alt="" />
            </swiper-slide>
            <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/activity/3.jpg" alt="" />
            </swiper-slide>
            <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/activity/4.jpg" alt="" />
            </swiper-slide>
            <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/activity/10.png" alt="" />
            </swiper-slide>
            <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/activity/8.jpg" alt="" />
            </swiper-slide>
            <swiper-slide class="ele_image_lunbo">
              <img src="@/assets/activity/11.png" alt="" />
            </swiper-slide> -->
          </swiper>
        </div>
      </div>
      <!-- </div>
      </dv-borderBox12> -->
    </div>
  </div>
</template>

<script>
import mapjidiVue from "../../../components/wchart/map_jidi.vue"
import flvjs from "flv.js"
import { getVideo } from "@/api/sale"

export default {
  components: {
    mapjidiVue,
  },
  data() {
    return {
      controls: {
        progressControl: false, // 隐藏进度条
        timeDivider: false, // 隐藏时间分割线
        durationDisplay: false, // 隐藏总时长
        remainingTimeDisplay: false, // 隐藏剩余时间
        fullscreenToggle: true // 隐藏全屏按钮
      },
      swiperOption: {
        slidesPerView: 3,
        direction: "horizontal", // 垂直切换选项
        spaceBetween: 10,
        // 设置自动轮播
        speed: 3000, //匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        // 设置轮播可循环
        loop: true,
      },
      detailsList: [
        {
          title: "赋能机构数量",
          number: "310",
          unit: "个",
        },
        {
          title: "服务城市数量",
          number: "60",
          unit: "个",
        },
        {
          title: "链接爱心企业",
          number: 3000,
          unit: "家",
        },
        {
          title: "培训课程数量",
          number: 87,
          unit: "门",
        },
        {
          title: "就业岗位类型",
          number: 28,
          unit: "类",
        },
        {
          title: "文娱活动数量",
          number: "1,023",
          unit: "次",
        },
      ],
      kfzyData: [
        {
          name: "三甲医院",
          value: "50",
          unit: "家",
        },
        {
          name: "体检机构",
          value: "300",
          unit: "家",
        },
        {
          name: "康复机构",
          value: "10",
          unit: "家",
        },
        {
          name: "康复医师",
          value: "50+",
          unit: "名",
        },
        {
          name: "康复治疗师",
          value: "500+",
          unit: "名",
        },
        {
          name: "康复护理师",
          value: "700+",
          unit: "名",
        },
      ],
      kfjgData: [
        {
          name: "中国康复研究中心",
        },
        {
          name: "慈铭体检中心",
        },
        {
          name: "首都医科大学附属北京康复医院",
        },
        {
          name: "爱康国宾体检中心",
        },
      ],
      peixunList: {
        header: [
          "采购单位",
          "产品订单",
          "数量",
          "涉及基地",
          "累计金额",
          "状态",
          "预计交付时间",
          "负责人",
        ],
        data: [
          ["通州区", "马驹桥镇网络直播培训", "2023-03-27"],
          ["延庆区", "延庆区残联面点制作职业技能培训", "2023-03-27"],
          ["延庆区", "延庆区残联收纳整理职业技能培训", "2023-03-23"],
          ["通州区", "马驹桥镇缝纫培训", "2023-03-22"],
          ["延庆区", "延庆区残联美甲职业技能培训", "2023-03-20"],
          [
            "平谷区",
            "平谷区金海湖镇残疾人中式面点制作培训-第三场",
            "2023-03-17",
          ],
          [
            "平谷区",
            "平谷区金海湖镇残疾人中式面点制作培训-第二场",
            "2023-03-16",
          ],
          [
            "平谷区",
            "平谷区金海湖镇残疾人中式面点制作培训-第一场",
            "2023-03-15",
          ],
          ["延庆区", "延庆区千家店镇插花培训", "2023-03-07"],
          ["通州区", "马驹桥镇中式面点培训", "2023-03-06"],
        ],
        index: true,
        columnWidth: [40, 50, 50, 50, 50, 50, 50, 50],
        align: ["center"],
        headerBGC: "#5689EA",
        oddRowBGC: "",
        evenRowBGC: "",
      },
      flv1:
        "https://v2bzce8mar5cq2m2pdemg74bq0djev9gu.mobgslb.tbcache.com/dtlive-sh.dingtalk.com/live_hp/334bdd73-a0d9-427f-864f-fba038e8ef1c.flv?auth_key=1690417443-0-0-347a0e3c724f8c7c8981bec3a171c3e5&ali_redirect_ex_hot=100",
      flv2:
        "https://v2p2o212fn1m9z48xj0blc5tjs8pzh12c.mobgslb.tbcache.com/dtlive-sh.dingtalk.com/live_hp/6a31fcac-d2db-4ee2-9e2e-8d6653b8a8e9.flv?auth_key=1690417595-0-0-99363252ba479ec29242333d599e7918&ali_redirect_ex_hot=100",
      zkzVideo: {},
      zsyVideo: {},
    }
  },
  mounted() {
    // this.flashPlay(this.flv1, this.$refs.video1)
    // this.flashPlay(this.flv2, this.$refs.video2)

    this.requestVideo()
  },
  methods: {
    onClickVideo(id) {
      const elVideo = document.getElementById(id)
      if (elVideo) {
        if (elVideo.requestFullscreen) {
          elVideo.requestFullscreen()
        } else if (elVideo.mozRequestFullScreen) {
          elVideo.mozRequestFullScreen()
        } else if (elVideo.webkitRequestFullscreen) {
          elVideo.webkitRequestFullscreen()
        } else if (elVideo.msRequestFullscreen) {
          elVideo.msRequestFullscreen()
        }
      }
    },
    async requestVideo() {
      try {
        const { data, code } = await getVideo()

        if (code === 0) {
          // this.liveDataList = data.list

          this.zkzVideo = data.list[1]
          this.zsyVideo = data.list[0]

          if (this.zkzVideo.live_type === 1) {
            this.flashPlay(this.zkzVideo.live_url, this.$refs.video1)
          }
          if (this.zsyVideo.live_type === 1) {
            this.flashPlay(this.zsyVideo.live_url, this.$refs.video2)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    flashPlay(url, elementId) {
      if (flvjs.isSupported()) {
        const flvPlayer = flvjs.createPlayer(
          {
            type: "flv",
            isLive: true,
            url: url, // 替换为您的FLV文件路径或URL
          },
          {
            //Config
            enableWorker: false,
            enableStashBuffer: false,
            //当带音频播放时,config部分配置项尽量采取默认状态,否则过分优化会造成卡死
          }
        )

        flvPlayer.attachMediaElement(elementId)
        flvPlayer.load()
        flvPlayer.play()
      } else {
        console.error("FLV.js is not supported in this browser")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.centerPage {
  margin-top: 1.2rem;
  width: 42rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mapWrapper {
  position: relative;
  .storeWrapper {
    position: absolute;
    bottom: 20px;
    left: 12px;
    color: #fff;
    p {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
}

.center2 {
  position: relative;
  height: 15rem;
  margin-top: 1rem;
  width: 100%;
}
.center3 {
  position: relative;
  height: 12.4rem;
  width: 100%;
}

.right-top-borderBox12 {
  width: inherit;
  height: inherit;
  padding: 5px;
  .right-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background-color: rgba(13, 28, 83, 0.3);
  }
}
.right-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  // background-color: rgba(13, 28, 83, 0.3);
}
.module-title {
  padding: 10px 10px;
  color: #bcdcff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  .iconfont {
    font-size: 0.875rem;
    margin-right: 0.625rem;
    color: #89e5ff;
    font-weight: 400;
  }
}

.left-video {
  width: 100%;
  .video_item {
    width: 100%;
    height: 12rem;
    padding: 0px 0.3rem;
  }
}
.responsive-iframe {
  width: 19rem;
  border-radius: 10px;
  height: 12rem;
  position: absolute;
}

// margin-top: -10px;
.ele_image_lunbo {
  width: 33.3%;
  cursor: pointer;
  img {
    width: 100%;
    padding: 10px 0px;
    height: 9.6rem;
    border-radius: 14px;
  }
}

//全屏按钮
// video::-webkit-media-controls-fullscreen-button {
//     display: none;
// }
//播放按钮
// video::-webkit-media-controls-play-button {
//     display: none;
// }
//进度条
// video::-webkit-media-controls-timeline {
//   display: none;
// }
//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
//音量按钮
// video::-webkit-media-controls-mute-button {
//   display: none;
// }
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none;
}
video {
  // mix-blend-mode: screen;
}

//所有控件
// video::-webkit-media-controls-enclosure {
//   display: none;
// }
</style>
