import { render, staticRenderFns } from "./pie_dis_type.vue?vue&type=template&id=223e0a01&scoped=true"
import script from "./pie_dis_type.vue?vue&type=script&lang=js"
export * from "./pie_dis_type.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "223e0a01",
  null
  
)

export default component.exports